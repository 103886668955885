import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

document.addEventListener("scroll", () => {
  const header = document.querySelector('.header');
  const logo = document.querySelector('.header__logo')
  const menu = document.querySelector('.menu')
  const ham = document.querySelector('.header__hamburger')
  toogleClassToElement('active', header)
  toogleClassToElement('active', logo)
  toogleClassToElement('active', menu)
  //toogleClassToElement('active', ham)
})

const toogleClassToElement = (className, element) => {
  if (window.pageYOffset == 0) {
    element.classList.remove(className)
  } else {
    element.classList.add(className)
  }
}
//animations
gsap.registerPlugin(ScrollTrigger)

const tlQuote = gsap.timeline({ scrollTrigger: ".quote" })
tlQuote.from(".quote__title", 0.5, { y: 30, opacity: 0, scale: 0.95 })
tlQuote.from(".quote__blockquote", 0.5, { y: 30, opacity: 0, scale: 0.95 }, 0.5)

const tlServices = gsap.timeline({ scrollTrigger: ".services" })
tlServices.from(".services__item", 0.5, { y: 30, opacity: 0, scale: 0.95, stagger: 0.2 })
tlServices.from(".services__ideas", 0.5, { y: 30, opacity: 0, scale: 0.95}, 0.5);
tlServices.from(".services__button", 0.5, { y: 30, opacity: 0, scale: 0.95}, 0.6);

const tlPortfolio = gsap.timeline({ scrollTrigger: ".portfolio" })
tlPortfolio.from(".portfolio__title", 0.5, { y: 30, scale: 0.95, opacity: 0 })
tlPortfolio.from(".portfolio__description", 0.5, { y: 30, scale: 0.95, opacity: 0 }, 0.2)

const tlAboutUs = gsap.timeline({ scrollTrigger: ".about-us" })
tlAboutUs.from(".about-us__title", 0.5, { y: 30, scale: 0.95, opacity: 0 })
tlAboutUs.from(".about-us__description", 0.5, { y: 30, scale: 0.95, opacity: 0 }, 0.2)

const tlAboutUsInfo = gsap.timeline({ scrollTrigger: ".about-us__info" })
tlAboutUsInfo.from(".about-us__image", 0.5, { y: 30, opacity: 0, scale: 0.95, stagger: 0.2 })

const tlClients = gsap.timeline({ scrollTrigger: ".clients" })
tlClients.from(".clients__title", 0.5, { y: 30, scale: 0.95, opacity: 0 })
tlClients.from(".clients__description", 0.5, { y: 30, scale: 0.95, opacity: 0 }, 0.2)
tlClients.from(".clients__list", 0.5, { y: 30, scale: 0.95, opacity: 0 }, 0.5)

const tlParallax = gsap.timeline({ scrollTrigger: ".parallax" })
tlParallax.from(".parallax__title", 0.5, { y: 30, scale: 0.95, opacity: 0 })
tlParallax.from(".parallax__button", 0.5, { y: 30, scale: 0.95, opacity: 0 }, 0.2)

const tlContact = gsap.timeline({ scrollTrigger: ".contact" })
tlContact.from(".contact__title", 0.5, { y: 30, scale: 0.95, opacity: 0 })
tlContact.from(".contact__description", 0.5, { y: 30, scale: 0.95, opacity: 0 }, 0.2)
tlContact.from(".contact__input", 0.5, { y: 30, opacity: 0, scale: 0.95, stagger: 0.2 }, 0.3)
tlContact.from(".contact__button", 0.5, { y: 30, scale: 0.95, opacity: 0 }, 1.2)
tlContact.from(".contact__subtitle", 0.5, { y: 30, scale: 0.95, opacity: 0, stagger: 0.2 }, 0.6)
tlContact.from(".contact__text", 0.5, { y: 30, scale: 0.95, opacity: 0, stagger: 0.2 }, 0.8)

const media = window.matchMedia("(max-width: 1250px)")

if (media.matches) {
const tlMenu = gsap.timeline()
tlMenu.from(".menu", 0.5, { y: "-100%" }).paused(true);
tlMenu.from(".menu__item", 0.5, { y: -30, scale: 0.95, opacity: 0, stagger: 0.1 }, 0.2)

//hamburger
const hamburger = document.querySelector(".header__hamburger")
console.log(hamburger)
hamburger.addEventListener("click", () => {
  if (hamburger.classList.contains('open')) {
    hamburger.classList.remove('open')
    tlMenu.reverse()
  } else {
    hamburger.classList.add('open')
    tlMenu.play()
  }
})
}